const Config = require("../Config/Config");

const service = {};

const callExtensionAPI = ( methodName, callback ) => {
  console.log( `PWA Extension: action ${ methodName } called` )

  if (!window.chrome || !window.chrome.runtime) {
    const message = 'PWA Extension could not be invoked: chrome.runtime is not available'
    console.warn( message )

    callback({ error: message })
    return
  }

  const extensionId = Config.CHROME_OS_PWA_PLAYER_EXTENSION_ID;
  window.chrome.runtime.sendMessage( extensionId, {methodName}, callback )

  console.log( `PWA Extension: action ${ methodName } message sent; extension id: ${ extensionId }` )
}

const invokeExtensionMethod = ( methodName ) => new Promise(( resolve, reject ) => {
  callExtensionAPI( methodName, response => {
    if( window.chrome && window.chrome.runtime && window.chrome.runtime.lastError ) {
      reject( window.chrome.runtime.lastError )
    } else if( response.error ) {
      reject( Error(response.error) )
    } else {
      resolve( response )
    }
  })
})

service.initializeAndGetConfiguration = () => {
  return invokeExtensionMethod( 'callInitializeAndGetConfiguration' )
}

module.exports = service;
