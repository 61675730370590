module.exports = {
  ENABLE_DEBUG_LOGGING: false,
  DISABLE_BQ_LOGGING: false,
  DISABLE_VIEWER_LEGACY_LOGGING: true,
  SERVER_URL: "https://rvaserver2.appspot.com",
  APPS_URL: "https://apps.risevision.com",
  PREVIEW_TRACKER_ID: "UA-82239-28",
  DISPLAY_TRACKER_ID: "UA-82239-32",
  SHARED_SCHEDULE_TRACKER_ID: "UA-82239-46",
  MESSAGING_SERVER_URL: "https://services.risevision.com/messaging/primus",
  LOCAL_MESSAGING_SERVER_URL: "http://localhost:8080",
  LOCAL_MESSAGING_CLIENT_NAME: "ws-client",
  SCREEN_SHARING_URL: "https://widgets.risevision.com/apps/screen-sharing/prod/client.html",
  CHROME_OS_PWA_PLAYER_EXTENSION_ID: "TBD",
  HTML_TEMPLATE_ENV: "stable",
  TESTING_COMPANIES: [
    "f114ad26-949d-44b4-87e9-8528afc76ce4",
    "4289a0f8-2c39-4f5d-9826-57c3d4c85d31",
    "a80d1075-33c6-48f4-80b4-b657b8864ac1",
    "72d7081e-ae1f-4586-a7d1-c3a92a8b32b7",
    "7cd3a040-7f2d-432c-b9d3-90f3ad412c63",
    "7e8554f2-b8dd-4375-ae36-5da744b85498",
    "d605d033-4b36-40bc-9ca2-f9d04bac56de",
    "f74d670e-fd8b-40cf-b2c4-6f4908a9eab4",
    "520ff8a5-4b7a-4be8-95bb-416c000c3c9c",
    "2b791be1-f7b0-4f3e-981a-df0f568a7151",
    "dc172dec-20c9-4e67-94da-20ca847afcb9",
    "a148b261-7cd7-4c65-9ee8-01ac3be76b27",
    "32b530bf-7687-484b-a7d6-2f157feb8ced"
  ]
};
