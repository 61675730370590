const config = require("./Config");

const global = {};

global.ONE_SECOND_DELAY = 1000;
global.MINUTE_UPDATE_INTERVAL = 60 * global.ONE_SECOND_DELAY;

global.DELAY_FOR_INJECTED_SCRIPTS = 500;

global.VIEWER_STATUS = {
  OK: 0,
  NO_COOKIE: 1,
  ID_SHARING_VIOLATION: 2,
  // UPDATE_INTERVAL_VIOLATION: 3,
  DISPLAY_NOT_FOUND: 4,
  COMPANY_NOT_FOUND: 5,
  BLOCKED: 6,
  CONTENT_NOT_FOUND: 7,
  NOT_SUBSCRIBED: 8,
  NO_CHANGES: -1,
};

global.VIEWER_VERSION = "2-01-202409162045";
global.DATA_SERVER_URL = config.SERVER_URL + "/v2/viewer/{0}/{1}";
global.CHANNEL_SERVER_URL = config.SERVER_URL + "/v2/viewer/display/";
global.GADGET_SERVER_URL = "http://www-open-opensocial.googleusercontent.com/gadgets/ifr";
global.ACTIVATION_CODE_URL = config.SERVER_URL + "/v3/viewer/display/register";

global.VIEWER_STARTUP_TIMESTAMP = new Date().getTime();
global.VIEWER_UNIQUE_ID = parseInt(Math.random() * 10000) + "_" + global.VIEWER_STARTUP_TIMESTAMP;
global.VIEWER_URL_IDENTIFIER = "uid=" + global.VIEWER_UNIQUE_ID + "&vv=" + global.VIEWER_VERSION;


module.exports = global;
