/* eslint-disable no-continue */

const Logger = require("../Logger");
const Utils = require("../Utils");
const PlaylistItemInfo = require("../Info/PlaylistItemInfo");
const ScreenSharingRenderer = require("../Renderer/ScreenSharingRenderer");
const HTMLTemplateMessaging = require("../Renderer/HTMLTemplateMessaging");
const ViewerDataInfo = require("../Data/ViewerDataInfo");
const ScreenSharingUIController = require("../UI/ScreenSharingUIController");
const PersistentNotificationPanel = require("../UI/PersistentNotificationPanel");
const PersistentNotificationType = require("../Info/PersistentNotificationType");
const ScreenShareMessageType = require("../Info/ScreenShareMessageType");

const ScreenSharingController = function (item, htmlName, containerName,
  onScheduleReady, onScheduleDone) {
  const factory = {};

  const ScreenSharingMode = {
    STANDARD: "standard",
    MODERATED: "moderated"
  };

  let status;

  let isPlaying = false;
  let isModeratorActive = false;

  let readyTimer;

  function isModerated() {
    return ViewerDataInfo.getScreenSharingMode() === ScreenSharingMode.MODERATED;
  }

  const _onPresentationDoneIfPlaying = () => {
    if (isPlaying && onScheduleDone) {
      onScheduleDone();
    }
  };

  function _viewerDataUpdated() {
    ScreenSharingUIController.refresh();
  }

  const _messageHandler = (message) => {
    if (message.topic === ScreenShareMessageType.JOIN_CODE) {
      PersistentNotificationPanel.setJoinCode(message.joinCode);

      if (!isModerated()) {
        ScreenSharingUIController.setJoinCode(message.joinCode);
      } else {
        ScreenSharingUIController.reset();
      }
    } else if (message.topic === ScreenShareMessageType.CHANNEL_ACTIVE) {
      if (message.isActive) {
        if (isModerated()) {
          isModeratorActive = true;
          PersistentNotificationPanel.show(PersistentNotificationType.SCREEN_SHARING_LOBBY);

          _onStreamReady();
        }
      } else {
        // hide if moderator was active
        PersistentNotificationPanel.clear(isModeratorActive || !isModerated());
        isModeratorActive = false;
        item.isSharing = false;

        _onPresentationDoneIfPlaying();
      }
    } else if (message.topic === ScreenShareMessageType.ERROR) {
      ScreenSharingUIController.setConnectionStatus(message.description);
    } else if (message.topic === ScreenShareMessageType.STREAM_CONNECTED) {
      PersistentNotificationPanel.clear(true);

      ScreenSharingUIController.setScreenSharingPlaying(true);

      _onStreamReady();
    } else if (message.topic === ScreenShareMessageType.STREAM_PAUSED) {
      PersistentNotificationPanel.show(PersistentNotificationType.SCREEN_SHARING_PAUSED);
    } else if (message.topic === ScreenShareMessageType.STREAM_DISCONNECTED) {
      if (PersistentNotificationPanel.isScreenSharingPaused()) {
        return;
      }

      PersistentNotificationPanel.clear();

      if (isModeratorActive) {
        PersistentNotificationPanel.show(PersistentNotificationType.SCREEN_SHARING_LOBBY);
      } else {
        item.isSharing = false;

        _onPresentationDoneIfPlaying();
      }

      ScreenSharingUIController.setScreenSharingPlaying(false);
    }
  };

  const htmlTemplateMessaging = new HTMLTemplateMessaging(_onPresentationDoneIfPlaying, htmlName, _messageHandler);

  const _setCurrentItem = () => {
    Logger.setEndpointLoggerContentFields({
      scheduleItemUrl: isPlaying ? "Screen Sharing" : null});
  };

  factory.init = () => {
    ScreenSharingRenderer.render(htmlName, containerName);

    ViewerDataInfo.addObserver(_viewerDataUpdated);

    PersistentNotificationPanel.setIsModerated(isModerated());

    if (onScheduleDone) {
      htmlTemplateMessaging.addListenerForTemplatePlayUntilDone();
    }

    htmlTemplateMessaging.onRiseComponentsReady(_onRiseComponentsReady, _onTemplateError);

    // Timer that will cut loading to a maximum of 20 seconds.
    readyTimer = setTimeout(_forceDataReady, 20 * 1000);

    status = PlaylistItemInfo.ADDED_STATUS;

    return 0;
  };

  let _onRiseComponentsReady = () => {
    if (status !== PlaylistItemInfo.READY_STATUS) {
      clearTimeout(readyTimer);

      status = PlaylistItemInfo.READY_STATUS;

      onScheduleReady();
    }
  };

  function _onTemplateError() {
    if (status === PlaylistItemInfo.ADDED_STATUS) {
      clearTimeout(readyTimer);

      status = PlaylistItemInfo.ERROR_STATUS;

      onScheduleReady();
    }
  }

  function _forceDataReady() {
    status = PlaylistItemInfo.READY_STATUS;

    Logger.viewerError( "E999999999", "Screen Sharing failed to load in 20 seconds" );

    onScheduleReady();
  }

  let _onStreamReady = () => {
    if (!item.isSharing) {
      item.isSharing = true;

      onScheduleReady(true);
    }
  };

  factory.unloadSchedule = () => {
    // only unload READY schedules that are NOT playing
    if (status >= PlaylistItemInfo.READY_STATUS && !isPlaying) {
      status = PlaylistItemInfo.UNLOADED_STATUS;

      factory.stop();
    }
  };

  factory.loadSchedule = () => {
    if (status === PlaylistItemInfo.UNLOADED_STATUS) {
      status = PlaylistItemInfo.LOADING_STATUS;
    }
  };

  factory.play = () => {
    if (!isPlaying) {
      isPlaying = true;

      htmlTemplateMessaging.sendPresentationEvent("rise-presentation-play");

      _setVisibility(true);
      _setCurrentItem();
    }
  };

  factory.stop = () => {
    Logger.logDebug(`Screen Sharing - Stop!`);

    // if (isPlaying) {
    isPlaying = false;

    htmlTemplateMessaging.sendPresentationEvent("rise-presentation-stop");

    _setVisibility(false);
    _setCurrentItem();
    // }
  };

  factory.pause = () => {
    Logger.logDebug(`Screen Sharing - Pause!`);

    // if (isPlaying) {
    isPlaying = false;

    htmlTemplateMessaging.sendPresentationEvent("rise-presentation-stop");

    _setVisibility(false);
    _setCurrentItem();
    // }
  };

  function _setVisibility(visible) {
    if (visible) {
      window.showElement(htmlName);
    } else {
      window.hideElement(htmlName);
    }
  }

  factory.isReady = () => {
    return status >= PlaylistItemInfo.READY_STATUS;
  };

  factory.getStatus = () => {
    return status;
  };

  factory.isModeratorActive = () => {
    return isModeratorActive;
  }

  factory.unload = () => {
    ViewerDataInfo.removeObserver(_viewerDataUpdated);

    ScreenSharingUIController.refresh();
  }

  factory.resize = () => {
    const height = window.innerHeight;
    const width = window.innerWidth;

    // [ad] bug fix - automatically re-size URL iframe size as well
    Utils.resizeContainer("iFrame_" + htmlName, width, height);
  };

  (function () {
    status = PlaylistItemInfo.LOADING_STATUS;
  }());

  return factory;
};

module.exports = ScreenSharingController;
